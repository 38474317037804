@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    font-family: Montserrat;
}

a {
    text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
    color: #262626;
}

p {
    color: #525252;
}


/* COLORS */
.primary-blue {
    color: #34336E;
}

.bg-primary-blue {
    background-color: #34336E;
}

.primary-blue-dark {
    color: #282357;
}

.bg-primary-blue-dark {
    background-color: #282357;
}

.primary-blue-light {
    color: #4A4D8F;
}

.bg-primary-blue-light {
    background-color: #4A4D8F;
}

.bg-blue-bg {
    background-color: #e8e9f1;
}

.black {
    color: #000000;
}

.bg-primary-black {
    background-color: #000000;
}

.grey-5 {
    color: #262626;
}

.bg-primary-grey-5 {
    background-color: #262626;
}

.grey-4 {
    color: #525252;
}

.bg-primary-grey-4 {
    background-color: #525252;
}

.grey-3 {
    color: #A3A3A3;
}

.bg-primary-grey-3 {
    background-color: #A3A3A3;
}

.grey-2 {
    color: #D4D4D4;
}

.bg-primary-grey-2 {
    background-color: #D4D4D4;
}

.grey-1 {
    color: #F5F5F5;
}

.bg-primary-grey-1 {
    background-color: #F5F5F5;
}

.white {
    color: #FFFFFF;
}

.bg-primary-white {
    background-color: #FFFFFF;
}

/* end Colors */
#login form{
    width: 90%;
}

.btn-primary-blue {
    color: #FFF;
    background-color: #34336E;
    padding: 8px 32px;
    border-radius: 40px;
    border: none;
}

.btn-primary-blue:hover {
    background-color: #4A4D8F;
    color: #fff;
}

.form-check-input:checked {
    background-color: #34336E;
    border-color: #34336E;
}


.logo-login{
    max-width: 50%;
    margin: 30px;
}

#fundo-login {
    background:
      linear-gradient(135deg,#0000 18.75%,#34336e 0 31.25%,#0000 0),
      repeating-linear-gradient(45deg,#34336e -6.25% 6.25%,#4a4d8f 0 18.75%);
    background-size: 55px 55px;
    height: 100vh;
}

.login {
    height: 100vh;
}

.user {
    margin-bottom: 2rem;
}

.input-search span.buscar {
    background-color: #fff;
    border-right: 0;
    border-radius: 30px 0 0 30px;
    border-top: 1px solid #A3A3A3;
    border-left: 1px solid #A3A3A3;
    border-bottom: 1px solid #A3A3A3;
}

.input-search input {
    border-left: 0;
    border-radius: 0 30px 30px 0;
    border-top: 1px solid #A3A3A3;
    border-right: 1px solid #A3A3A3;
    border-bottom: 1px solid #A3A3A3;
}

.sidebar {
    height: 100vh;
    background-color: #FFF;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    position: fixed;
}

.sidebar img {
    max-width: 80%;
    margin: 30px 0 20px;
}

#conteudo {
    background-color: #FFF;
    z-index: 0;
}

.sidebar ul {
    padding: 8px 0;
}

.sidebar ul li {
    list-style-type: none;
    margin: 1rem 0;
    padding: 8px 16px;

}

.sidebar ul li#active {
    background-color: #e8e9f1;
    border-radius: 6px;
}

.sidebar ul li a {
    display: flex;
    align-items: center;
    color: #34336E;
}

.sidebar ul li span {
    margin-right: 0.50rem;
}

.topo-conteudo {
    margin: 2rem 0 0.75rem;
}

.topo-conteudo dropdown {
    margin: 0;
    
}

.topo-conteudo button{
    margin: 0;
}

.dropdown .btn-dropdown {
    background-color: #FFF;
    color: #525252;
    border: none;
    border-radius: 6px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1);
    padding: 1rem;
    font-size: 1rem;
}

.btn-dropdown-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
    color: #34336E;
    background-color: #FFF;
    border-color: #FFF;
}

.topo-conteudo h1 {
    color: #34336E;
}

.breadcrumb-item a {
    color: #525252;
}

.breadcrumb-item.active {
    color: #34336E;
}

.table tr th.topo{
    background-color: #E8E9F1;
    color: #34336E;
    padding: 2rem 1rem;
    border: none;
    font-weight: 500;
}

.table tr th.topo:First-child{
   padding: 2rem;  
}

.table tr th.topo:First-child {
    border-radius: 8px 0 0 8px;
}

.table tr th.topo:Last-child {
    border-radius: 0px 8px 8px 0;
}

.table tr td {
    border: none;
        padding: 1.25rem 1rem;
}

.table tr td:First-child {
    padding: 1.25rem 2rem;
}

span.email {
    font-size: 0.85rem;
}

.btn-desativar {
    color: #D83E39;
    border: 1px solid #D83E39 ;
    background-color: #fff;
    border-radius: 30px;
}

.card-body .btn-desativar{
    width: 70%;
    margin: 1rem auto 0;
}

.cadastro-btn .btn-desativar, .btn-primary-blue {
    width: 13rem;
    margin-bottom: 1rem;
}

.btn-desativar:hover {
    color: #fff;
    border: 1px solid #D83E39 ;
    background-color: #D83E39;
    border-radius: 30px;
}

.btn-ativar {
    color: #34336E;
    border: 1px solid #34336E ;
    background-color: #fff;
    border-radius: 30px;
}

.card .btn-ativar {
    width: 70%;
    margin: 1rem auto 0;
}

.card-title {
    color: #525252;
}

.btn-ativar:hover {
    color: #fff;
    border: 1px solid #34336E ;
    background-color: #34336E;
    border-radius: 30px;
}

.alert-danger {
    background-color: #FEF2F2 !important;
    color: #DC2626 !important;
    border: none;
    text-align: initial;
    display: inline-flex;
}

.alert-login {
    width: 90% !important;
}

.alert-padrao {
    width: 100% !important;
    background-color: #E8E9F1 !important;
    color: #34336E !important;
    border: none;
    text-align: initial;
    display: inline-flex;
}

.alert-success {
    width: 100% !important;
    background-color: #F0FDF4 !important;
    color: #15803D !important;
    border: none;
    text-align: initial;
    display: inline-flex;
}

.alert-padrao span, .alert-danger span, .alert-success span{
    margin-right: 8px;
}


.card {
    border: none !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1) !important;
    border-radius: 16px !important;
    margin-top: 16px;
    margin-bottom: 16px;
}

.card-body {
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.text-bg-success {
    background-color: #F0FDF4 !important;
    color: #22C55E !important;
    width: 100%;
    padding: 6px;
    font-weight: 400;
}

.text-bg-danger {
    background-color: #FEF2F2 !important;
    color: #DC2626 !important;
    width: 100%;
    padding: 6px;
    font-weight: 400;
}

.text-bg-success-card {
    background-color: #F0FDF4 !important;
    color: #22C55E !important;
    width: 100%;
    padding: 6px;
    font-weight: 400;
    margin-bottom: 10px;
}

.text-bg-danger-card {
    background-color: #FEF2F2 !important;
    color: #DC2626 !important;
    width: 100%;
    padding: 6px;
    font-weight: 400;
    margin-bottom: 10px;
}

.dropdown .dropdown-acao {
    background-color: #FFF;
    color: #525252;
    border: none;
    border-radius: 6px;
    box-shadow: none;
    padding: 0rem;
    font-size: 1rem;
    width: 100px;
    float: right;
}

.btn-filter {
    box-shadow: none;
    background-color: #FFF;
    border: 1px solid #A3A3A3;
    border-radius: 30px;
    color: #525252;
    display: inline-flex;
}

.btn-filter:hover {
    background-color: #34336E;
    border: 1px solid #34336E;
    color: #fff;
}

.btn-filter span{
    font-size: 1.4rem;
    margin-right: 6px;
}

.ordenacao {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
}

.ordenacao label {
    margin-right: 0.5rem;
}

.ordenacao span {
    margin-left: 1rem;
}

.ordenacao a {
    display: flex;
    color: #525252;
}

.ordenacao label {
    font-size: 0.9rem;
    color: #525252;
        text-align: right;
    padding-right: 0;

}

.ordenacao select {
    border: 1px solid #A3A3A3;
    border-radius: 30px;
    color: #525252;
    width: auto;
}

.cards, .cards a {
    display: flex;
    align-items: center;
    font-size: 2rem;
    color: #525252;
    justify-content: flex-end;
}

.row-padding-conteudo {
     padding: 0 2%;
}

.no-padding-right {
    padding-right: 0;
}

@media only screen and (min-width: 835px) {
    .desktop-hide{ display: none !important; }
}

.center-icons {
    display: inline-flex;
    align-items: center;
}

a.align-icon {
    display: inline-flex;
    color: #525252;
}

a.action-disable {
    color: #DC2626;
}

a.action-disable:hover {
    color: #DC2626;
}

.align-icon span {
    font-size: 1.5rem;
    margin-right: 0.6rem;
}

.dropdown-menu {
    border: none !important;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.1) !important;
}

.card-text span {
    font-size: 1.1rem;
    margin-right: 0.4rem;
}

.row.text-center a {
    display: flex;
    justify-content: center;
}

p.card-text {
    width: 100%;
    margin-bottom: 6px;
    font-size: 0.9rem;
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 4px !important;
    z-index: 2;
    height: 100%;
    padding: 1rem 1.75rem;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}

ul.padrao {
    padding-left: 1rem;
}

.login .btn-primary-blue {
    margin: 2rem;
}

.alert-w100 {
    width: 100%;
}

.pagination {
    display: flex;
    justify-content: center;
}

.pagination li.prev-next a {
    border: none;
    color: #A3A3A3;
    background-color: #FFF;
}

.pagination li.prev-next a:hover {
    color: #34336E;
    background-color: #FFF;
}

.pagination a.active {
    background-color: #34336E;
    border: none;
    border-radius: 8px;
}

.pagination a.active:hover {
    color: #FFF;
}

.pagination a {
    background-color: #F5F5F5;
    border: none;
    color: #525252;
    border-radius: 8px;
    margin-right: 8px;
}

.pagination a:hover {
    color: #34336E;
    background-color: #E8E9F1;
}

/* CSS/Mobile */

@media only screen and (max-width: 600px) {

.mobile-hide{ display: none !important; }

#fundo-login {
    background:
      linear-gradient(135deg,#0000 18.75%,#34336e 0 31.25%,#0000 0),
      repeating-linear-gradient(45deg,#34336e -6.25% 6.25%,#4a4d8f 0 18.75%);
    background-size: 55px 55px;
    position: fixed;
    bottom: 0%;
    height: 30px;
}

.login {
    height: auto;
}

.logo-login{
    max-width: 50%;
    margin: 2rem 30px;
}

.check-center {
    text-align: center !important;
}

.check-center .form-check-input {
    float: none;
    margin-right: 6px;
}

#login {
    width: 100% !important;
}

#login form{
    width: 100%;
}

.alert-danger {
    width: 100% !important;
}

.btn-filter {
    width: 100%;
    justify-content: center;
}

.ordenacao {
    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.dropdown>.dropdown-menu {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    display: block;
    transition: all 0.1s ease-out;
}

.dropdown:hover>.dropdown-menu {
    padding: 20px 0;
    display: block;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.dropdown-item:hover {
    color: #FFF;
    background-color: #000;
}

.navbar img {
    max-width: 200px;
}

.nav-item span {
    margin-right: 1rem;
}

.nav-item a {
    display: inline-flex;
}

.cadastro-btn .btn-desativar, .btn-primary-blue {
    width: auto;
    margin: 0 auto 1rem;
}

.cadastro-btn {
    text-align: center !important;
}


}

/* CSS/TABLET */
@media only screen and (max-width: 835px) {

.tablet-hide{ display: none !important; }

#fundo-login {
    background:
      linear-gradient(135deg,#0000 18.75%,#34336e 0 31.25%,#0000 0),
      repeating-linear-gradient(45deg,#34336e -6.25% 6.25%,#4a4d8f 0 18.75%);
    background-size: 55px 55px;
    position: fixed;
    bottom: 0%;
    height: 30px;
}

#login {
    width: 80%;
}

#login form{
    width: 100%;
}

.alert-danger {
    width: 100% !important;
}

.dropdown>.dropdown-menu {
    visibility: hidden;
    opacity: 0;
    top: 100%;
    display: block;
    transition: all 0.1s ease-out;
}

.dropdown:hover>.dropdown-menu {
    padding: 20px 0;
    display: block;
    visibility: visible;
    opacity: 1;
    transition: all 0.3s ease-in-out;
}

.dropdown-item:hover {
    color: #FFF;
    background-color: #000;
}

.navbar img {
    max-width: 200px;
}

.navbar .container {
    max-width: 95%;
}

.nav-item a {
    display: inline-flex;
}

.nav-item span {
    margin-right: 1rem;
}

}
